<template>
  <div v-if="$hasFonction(user, 'Activer devises')">
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div v-show="add">
        <app-collapse accordion>
          <app-collapse-item
            v-if="$hasFonction(user, 'Ajouter devises')"
            isVisible
            name="header"
            title="AJOUTER UNE DEVISE"
            color="primary"
          >
            <validation-observer ref="formValidation">
              <b-form class="mt-2" @submit.prevent>
                <b-row>
                  <b-col sm="4">
                    <b-form-group label="Nom">
                      <validation-provider
                        #default="{ errors }"
                        name="Nom"
                        rules="required"
                      >
                        <b-form-input
                          v-model="addedItem.name"
                          @click="resetInputName"
                          placeholder="Nom"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                        <small class="text-danger">{{ errorsName }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group label="Abbréviation">
                      <validation-provider
                        #default="{ errors }"
                        name="Abbréviation"
                        rules="required"
                      >
                        <b-form-input
                          v-model="addedItem.abbreviation"
                          @click="resetInputAbbreviation"
                          placeholder="Abbréviation"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                        <small class="text-danger">{{
                          errorsAbbreviation
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group label="Valeur">
                      <validation-provider
                        #default="{ errors }"
                        name="Valeur"
                        :rules="devises.length ? 'required' : ''"
                      >
                        <!-- <b-form-input
                          v-model="addedItem.value"
                          :disabled="!devises.length"
                          @click="resetInputValeur"
                          placeholder="0"
                        /> -->

                        <cleave
                          id="valeur"
                          v-model="addedItem.value"
                          class="form-control"
                          :raw="false"
                          :options="numberFormat"
                          :disabled="!devises.length"
                          placeholder="0"
                          @click="resetInputValeur"
                          :state="errors.length > 0 ? false : null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                        <small class="text-danger">{{ errorsValeur }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-button
                      variant="outline-secondary"
                      type="reset"
                      class="mt-2 mr-1"
                      @click="resetInput"
                    >
                      Annuler
                    </b-button>

                    <b-button
                      v-if="etatButton"
                      variant="primary"
                      class="mt-2"
                      :disabled="isOperating"
                      @click="addCurrency"
                    >
                      Enregistrer
                    </b-button>

                    <b-button v-else variant="primary">
                      <b-icon-arrow-clockwise
                        animation="spin"
                        font-scale="1"
                      ></b-icon-arrow-clockwise>
                      En cours
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </app-collapse-item>
        </app-collapse>
      </div>

      <div :hidden="hidden">
        <b-row>
          <b-col lg="3" cols="12" order="2" order-lg="1">
            <b-card no-body>
              <b-card-body>
                <b-card-title
                  b-card-title
                  class="text-uppercase font-weight-bolder"
                  >Devises manifeste</b-card-title
                >
                <hr />
                <validation-observer ref="formMValidation">
                  <b-form class="auth-login-form mt-2" @submit.prevent>
                    <b-form-group label="Devise Achat" label-for="dev-achat">
                      <validation-provider
                        #default="{ errors }"
                        name="Devise Achat"
                        rules="required"
                      >
                        <b-form-select
                          v-model="deviseManif.dev_achat"
                          :options="devises"
                          value-field="id"
                          text-field="name"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small class="text-danger">{{ errorsAchat }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group label="Devise CI" label-for="dev-ci">
                      <validation-provider
                        #default="{ errors }"
                        name="Devise Carte Importateur"
                        rules="required"
                      >
                        <b-form-select
                          v-model="deviseManif.dev_carte_imp"
                          :options="devises"
                          value-field="id"
                          text-field="name"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small class="text-danger">{{ errorsCI }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label="Devise Transport"
                      label-for="dev-transport"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Devise Transport"
                        rules="required"
                      >
                        <b-form-select
                          v-model="deviseManif.dev_transport"
                          :options="devises"
                          value-field="id"
                          text-field="name"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small class="text-danger">{{ errorsTransport }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group label="Devise Frais" label-for="dev-frais">
                      <validation-provider
                        #default="{ errors }"
                        name="Devise Frais"
                        rules="required"
                      >
                        <b-form-select
                          v-model="deviseManif.dev_frais"
                          :options="devises"
                          value-field="id"
                          text-field="name"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small class="text-danger">{{ errorsFrais }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group label="Devise Vente" label-for="dev-vente">
                      <validation-provider
                        #default="{ errors }"
                        name="Devise Vente"
                        rules="required"
                      >
                        <b-form-select
                          v-model="deviseManif.dev_vente"
                          :options="devises"
                          value-field="id"
                          text-field="name"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small class="text-danger">{{ errorsVente }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- submit buttons -->
                    <div v-if="etatButtonDevise">
                      <b-button
                        v-if="$hasFonction(user, 'Modifier devise manifeste')"
                        type="submit"
                        variant="primary"
                        :disabled="isOperating"
                        @click="saveManifeste()"
                        block
                      >
                        Enregistrer
                      </b-button>
                    </div>

                    <b-button v-else variant="primary" block>
                      <b-icon-arrow-clockwise
                        animation="spin"
                        font-scale="1"
                      ></b-icon-arrow-clockwise>
                      En cours
                    </b-button>
                  </b-form>
                </validation-observer>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col lg="9" cols="12" order="1" order-lg="2" class="mb-1">
            <div>
              <app-collapse accordion>
                <app-collapse-item
                  ref="collapse"
                  name="header"
                  title="AJOUTER UNE DEVISE"
                  color="primary"
                  v-if="$hasFonction(user, 'Ajouter devises')"
                >
                  <validation-observer ref="formValidation">
                    <b-form class="mt-2" @submit.prevent>
                      <b-row>
                        <b-col sm="4">
                          <b-form-group label="Nom">
                            <validation-provider
                              #default="{ errors }"
                              name="Nom"
                              rules="required"
                            >
                              <b-form-input
                                v-model="addedItem.name"
                                @click="resetInputName"
                                placeholder="Nom"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                              <small class="text-danger">{{
                                errorsName
                              }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col sm="4">
                          <b-form-group label="Abbréviation">
                            <validation-provider
                              #default="{ errors }"
                              name="Abbréviation"
                              rules="required"
                            >
                              <b-form-input
                                v-model="addedItem.abbreviation"
                                @click="resetInputAbbreviation"
                                placeholder="Abbréviation"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                              <small class="text-danger">{{
                                errorsAbbreviation
                              }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col sm="4">
                          <b-form-group label="Valeur">
                            <validation-provider
                              #default="{ errors }"
                              name="Valeur"
                              :rules="devises.length ? 'required' : ''"
                            >
                              <!-- <b-form-input
                                v-model="addedItem.value"
                                :disabled="!devises.length"
                                @click="resetInputValeur"
                                placeholder="0"
                              /> -->

                              <cleave
                                id="valeur"
                                v-model="addedItem.value"
                                class="form-control"
                                :raw="false"
                                :options="numberFormat"
                                :disabled="!devises.length"
                                placeholder="0"
                                @click="resetInputValeur"
                                :state="errors.length > 0 ? false : null"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                              <small class="text-danger">{{
                                errorsValeur
                              }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col cols="12">
                          <b-button
                            variant="outline-secondary"
                            type="reset"
                            class="mt-2 mr-1"
                            @click="resetInput"
                          >
                            Annuler
                          </b-button>

                          <b-button
                            v-if="etatButton"
                            variant="primary"
                            class="mt-2"
                            :disabled="isOperating"
                            @click="addCurrency"
                          >
                            Enregistrer
                          </b-button>

                          <b-button v-else variant="primary">
                            <b-icon-arrow-clockwise
                              animation="spin"
                              font-scale="1"
                            ></b-icon-arrow-clockwise>
                            En cours
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </app-collapse-item>
              </app-collapse>

              <b-modal
                id="modal-modification-utilisateur"
                v-model="modalUpdate"
                :ok-disabled="isOperating"
                title="Modifier les informations"
                @hidden="resetModal"
                hide-footer
                centered
                size="lg"
              >
                <b-form class="mt-2" @submit.prevent>
                  <validation-observer ref="updateFormValidation">
                    <b-row>
                      <b-col sm="4">
                        <b-form-group label="Nom">
                          <validation-provider
                            #default="{ errors }"
                            name="Nom"
                            rules="required"
                          >
                            <b-form-input
                              v-model="editedItem.name"
                              @click="resetInputNameModal"
                              placeholder="Nom"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-danger">{{
                              errorsNameModal
                            }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group label="Abbréviation">
                          <validation-provider
                            #default="{ errors }"
                            name="Abbréviation"
                            rules="required"
                          >
                            <b-form-input
                              v-model="editedItem.abbreviation"
                              @click="resetInputAbbreviationModal"
                              placeholder="Abbréviation"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-danger">{{
                              errorsAbbreviationModal
                            }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group label="Valeur">
                          <validation-provider
                            #default="{ errors }"
                            name="Valeur"
                            rules="required"
                          >
                            <b-form-input
                              v-model="editedItem.value"
                              @click="resetInputValeurModal"
                              :disabled="!devises.length"
                              placeholder="0"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-danger">{{
                              errorsValeurModal
                            }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-button
                          variant="outline-secondary"
                          type="reset"
                          class="mt-2 mr-1"
                          @click="modalUpdate = false"
                        >
                          Annuler
                        </b-button>

                        <b-button
                          v-if="etatButtonEdit"
                          variant="primary"
                          class="mt-2"
                          :disabled="isOperating"
                          @click="updateItem"
                        >
                          Enregistrer
                        </b-button>

                        <b-button v-else variant="primary" class="mt-2">
                          <b-icon-arrow-clockwise
                            animation="spin"
                            font-scale="1"
                          ></b-icon-arrow-clockwise>
                          En cours
                        </b-button>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </b-form>
              </b-modal>
            </div>

            <b-card no-body title="" class="mt-1">
              <div class="container-search-btn flex-stack my-2 mx-2">
                <!-- search start -->
                <div class="container-search mr-1">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Recherche"
                  >
                  </b-form-input>
                </div>
                <!-- search end -->

                <!-- bouton export start -->
				<div>
					<b-button
					variant="outline-primary"
					size="sm"
					@click="ExportFomatExcel"
					>
					<b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
					Exporter
					</b-button>

          <b-button
					variant="outline-primary"
					size="sm"
          class="ml-sm-2 mt-2 mt-sm-0"
					@click="ExportAllToFomatExcel"
					>
					<b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
					Tout Exporter
					</b-button>
          
				</div>
                <!-- bouton export end -->
              </div>

              <!-- table start -->
              <b-table
                v-model="ExcelDevises"
                id="my-table"
                responsive="sm"
                :fields="headers"
                :items="devises"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
                :filter="filter"
                show-empty
                class="border-bottom"
              >
                <!-- iconAction start -->
                <template #cell(actions)="data">
                  <div class="row justify-content-center">
                    <div>
                      <b-button
                        :id="'button-1' + data.item.id"
                        size="sm"
                        variant="flat-primary"
                        class="btn-icon"
                        :disabled="data.item.devise_principale === 1"
                        @click="editItem(data.item)"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                      <!-- <b-tooltip
                        :target="'button-1' + data.item.id"
                        title="Editer"
                        variant="dark"
                      ></b-tooltip> -->
                    </div>
                    <div>
                      <b-button
                        :id="'button-2' + data.item.id"
                        size="sm"
                        variant="flat-danger"
                        :disabled="data.item.devise_principale === 1"
                        @click="showMsgBoxOne(data.item)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                      <!-- <b-tooltip
                        :target="'button-2' + data.item.id"
                        title="Supprimer"
                        variant="dark"
                      ></b-tooltip> -->
                    </div>
                  </div>
                </template>
                <!-- iconAction end -->

                <template #empty>
                  <div class="text-center">
                    <span class="text-nowrap">Aucune donnée existante</span>
                  </div>
                </template>
                <template #emptyfiltered>
                  <div class="text-center">
                    <span class="text-nowrap"
                      >L'élément recherché n'est pas dans la liste</span
                    >
                  </div>
                </template>
              </b-table>
              <!-- table end -->

              <div class="my-2 mx-2 row">
                <!-- per-page end -->
                <div class="col">
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    style="width: 70px"
                  ></b-form-select>
                  <span class="text-nowrap ml-1" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
	                  <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                    </span>
                  <span class="text-nowrap ml-1" v-if="allRows == 0"> 0 éléments</span>
                </div>
                <!-- per-page end -->

                <!-- pagination start -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  aria-controls="my-table"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
                <!-- pagination end -->
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
        <b-button variant="primary" size="sm" @click="etatAdd">
          <b-icon-plus></b-icon-plus>
          AJOUTER
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import downloadExcel from "json2excel-js";
import { json2excel, excel2json } from "js2excel";
import { mapState } from "vuex";
import {
  BForm,
  BCard,
  BTable,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BIconPlus,
  BInputGroup,
  BInputGroupAppend,
  BCardTitle,
  BCardBody,
  BButton,
  BBadge,
  BTooltip,
  BPagination,
  BIconArrowClockwise,
  BIconCircleFill,
  BIconFileEarmarkArrowDownFill,
  BRow,
  BCol,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

export default {
  components: {
    Cleave,
    AppCollapseItem,
    VueGoodTable,
    BFormSelect,
    AppCollapse,
    BForm,
    BCard,
    BTable,
    EmptyList,
    BIconPlus,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BIconArrowClockwise,
    BIconCircleFill,
    BIconFileEarmarkArrowDownFill,
    BCardTitle,
    BCardBody,
    BButton,
    BBadge,
    BCardCode,
    BRow,
    BCol,
    BTooltip,
    BPagination,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      numberFormat: {
        delimiter: " ",
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      etatButtonDevise: true,
      etatButton: true,
      etatButtonEdit: true,
      add: false,
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      addedItem: {},
      editedItem: {},
      deletedItem: {},
      deviseManif: {
        dev_achat: "",
        dev_carte_imp: "",
        dev_transport: "",
        dev_frais: "",
        dev_vente: "",
      },
      isOperating: false,
      modalUpdate: false,
      errorsName: null,
      errorsAbbreviation: null,
      errorsValeur: null,
      errorsAchat: null,
      errorsCI: null,
      errorsTransport: null,
      errorsFrais: null,
      errorsVente: null,
      errorsNameModal: null,
      devises: [],
      errorsAbbreviationModal: null,
      errorsValeurModal: null,
      required,
      allRows: 0,
      integer,
      perPage: 5,
      currentPage: 1,
      filter: null,
      pageOptions: [],
      headers: [
        {
          key: "name",
          label: "Nom devise",
          sortable: true,
        },
        {
          key: "abbreviation",
          label: "Abbréviation",
          sortable: true,
        },
        {
          key: "value",
          label: "Valeur",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          class: "w-25",
        },
      ],
      ExcelDevises: [],
    };
  },
  computed: {
    totalRows() {
      // Set the initial number of items
      this.pageOptions = [5, 10, 25, 50, 100, { value: this.devises.length, text: "Tout" } ]
      return this.allRows;
    },
    ...mapState({
      user: (state) => state.user.auth,
    }),

    devisePrincipale() {
      if (this.devises.length) {
        return this.devises.find(
          ({ devise_principale }) => devise_principale === 1
        );
      } else {
        return false;
      }
    },

    // devises() {
    //   return this.$store.state.devises.items.reverse();
    // },

    devManifeste() {
      return this.$store.state.devises.devManifeste;
    },
  },

  created() {
    // this.$store.dispatch("fetchDevises");
    // this.$store.dispatch("fetchDevisesManifeste");
  },
  mounted() {
    this.getManifDevises();
    this.getDevises();
  },

  methods: {
    etatAdd() {
      this.add = true;
      this.hiddenEmpty = true;
    },

    resetForm() {
      this.addedItem = {};
    },

    resetInput() {
      this.resetInputName();
      this.resetInputAbbreviation();
      this.resetInputValeur();
    },

    resetInputName() {
      if (this.errorsName) {
        this.errorsName = null;
      }
    },

    resetInputAbbreviation() {
      if (this.errorsAbbreviation) {
        this.errorsAbbreviation = null;
      }
    },

    resetInputValeur() {
      if (this.errorsValeur) {
        this.errorsValeur = null;
      }
    },

    resetModal() {
      this.editedItem = {};
      this.resetInputModal();
    },

    resetInputModal() {
      this.resetInputNameModal();
      this.resetInputAbbreviationModal();
      this.resetInputValeurModal();
    },

    resetInputNameModal() {
      if (this.errorsNameModal) {
        this.errorsNameModal = null;
      }
    },

    resetInputAbbreviationModal() {
      if (this.errorsAbbreviationModal) {
        this.errorsAbbreviationModal = null;
      }
    },

    resetInputValeurModal() {
      if (this.errorsValeurModal) {
        this.errorsValeurModal = null;
      }
    },


    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
      },

    getDevises() {
      this.$http
        .get("/devises")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }

          this.devises = res.data.data.reverse();

          if (this.devises.length) {
            this.hidden = false;
            this.hiddenEmpty = true;
          } else {
            this.hidden = true;
            this.hiddenEmpty = false;
          }

          this.allRows = this.devises.length

        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },

    addCurrency() {
      this.$refs.formValidation.validate().then((success) => {
        if (success) {
          this.etatButton = false;
          this.isOperating = true;
          this.addedItem.value = this.$thousandFormater(this.addedItem.value);

          this.$http
            .post("/devises", this.addedItem)
            .then((result) => {
              this.isOperating = false;
              if (result.data.success) {
                this.etatButton = true;
                this.addedItem = JSON.parse(
                  JSON.stringify({ type: Object, default: () => {} })
                );
                this.$store.dispatch("fetchDevises");
                this.getDevises();

                this.$refs.formValidation.reset();
                //this.$store.commit("addDevise", result.data.data);
                this.add = false;
                this.showToast("Devise ajoutée avec succès", "success");
                if (this.devises.length) {
                  this.$refs.collapse.updateVisible(false);
                }
              } else {
                this.etatButton = true;
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((e) => {
              this.etatButton = true;
              this.isOperating = false;
              var err = e.response.data.errors;
              // this.isOperating = false;

              if (err) {
                if (err.name) {
                  this.errorsName = err.name[0];
                }

                if (err.abbreviation) {
                  this.errorsAbbreviation = err.abbreviation[0];
                }

                if (err.value) {
                  this.errorsValeur = err.value[0];
                }
              }
            });
        }
      });
    },

    saveManifeste() {
      this.$refs.formMValidation.validate().then((success) => {
        if (success) {
          this.etatButtonDevise = false;
          this.isOperating = true;
          this.$http
            .post("/editer-principale", this.deviseManif)
            .then((result) => {
              this.isOperating = false;
              if (result.data.success) {
                this.etatButtonDevise = true;
                this.$store.commit("setManifesteDevises", result.data.data[0]);
                this.showToast("Devise ajoutée avec succès", "success");
                this.getManifDevises();
              } else {
                this.etatButtonDevise = true;
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((e) => {
              this.etatButtonDevise = true;
              this.isOperating = false;
              var err = e.response.data.errors;
              // this.isOperating = false;

              if (err) {
                if (err.achat) {
                  this.errorsAchat = err.achat[0];
                }

                if (err.ci) {
                  this.errorsCI = err.ci[0];
                }

                if (err.transport) {
                  this.errorsTransport = err.transport[0];
                }

                if (err.frais) {
                  this.errorsFrais = err.frais[0];
                }

                if (err.vente) {
                  this.errorsVente = err.vente[0];
                }
              }
            });
        }
      });
    },

    getManifDevises() {
      this.$http
        .get("/devises-manifeste")
        .then((response) => {
          this.manifsdevises = response.data.data[0];

          this.deviseManif.dev_achat = parseInt(this.manifsdevises.dev_achat);
          this.deviseManif.dev_carte_imp = parseInt(
            this.manifsdevises.dev_carte_imp
          );
          this.deviseManif.dev_frais = parseInt(this.manifsdevises.dev_frais);
          this.deviseManif.dev_transport = parseInt(
            this.manifsdevises.dev_transport
          );
          this.deviseManif.dev_vente = parseInt(this.manifsdevises.dev_vente);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateItem(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      this.$refs.updateFormValidation.validate().then((success) => {
        if (success) {
          this.etatButtonEdit = false;
          this.isOperating = true;
          this.$http
            .put("/devises/" + this.editedItem.id, this.editedItem)
            .then((result) => {
              this.isOperating = false;
              if (result.data.success) {
                this.etatButtonEdit = true;
                this.modalUpdate = false;
                this.getDevises();
                this.$refs.formValidation.reset();
                // this.$store.commit("editDevise", this.editedItem);
                this.showToast("Devise modifiée avec succès", "success");
              } else {
                this.etatButtonEdit = true;
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((e) => {
              this.etatButtonEdit = true;
              this.isOperating = false;
              var err = e.response.data.errors;
              // this.isOperating = false;

              if (err.name) {
                this.errorsNameModal = err.name[0];
              }

              if (err.abbreviation) {
                this.errorsAbbreviationModal = err.abbreviation[0];
              }

              if (err.value) {
                this.errorsValeurModal = err.value[0];
              }
            });
        }
      });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    editItem(item) {
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.modalUpdate = true;
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    showMsgBoxOne(deletedItem) {
      this.$bvModal
        .msgBoxConfirm("Voulez vous vraiment supprimer cet élement ?", {
          title: "Supprimer une devise",
          okVariant: "danger",
          cancelVariant: "outline-secondary",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.$http
              .delete(`/devises/${deletedItem.id}`)
              .then((result) => {
                this.$store.dispatch("fetchDevises");
                //	this.$store.commit("removeDevise", result.data.data);
                this.showToast("Suppression effectuée avec succès", "success");
              })
              .catch((err) => {
                this.showToast(err.response.data.data.suppression, "danger");
              });
          }
        });
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.ExcelDevises.forEach((element) => {
        obj = {
          DEVISE: element.name,
          ABBRÉVIATION: element.abbreviation,
          VALEUR: element.value,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Devises",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.devises.forEach((element) => {
        obj = {
          DEVISE: element.name,
          ABBRÉVIATION: element.abbreviation,
          VALEUR: element.value,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des Devises",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    
  },
};
</script>

<style>
	#loader {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #f8f8f8;
		padding-top: 15%;
		z-index: 1000;
	}

	.container-search-btn {
		display: flex !important;
	}

	.flex-stack {
		justify-content: space-between;
		align-items: center;
	}
	
	@media screen and (max-width: 435px) {
		.container-search-btn {
			display: flex !important;
		}

		.container-search {
			width: 50%;
		}
	}

	@media screen and (max-width: 327px) {
		.container-search {
			width: 100%;
			margin-bottom: 10px;
		}

		.container-search-btn {
			display: block !important;
		}
	}
</style>
